import {
  ApiResponse,
  Locale,
  StatusStaticReference,
} from '@/shared/types/generic';
import {
  CreatePartnerPayload,
  EditPartnerPayload,
  Partner,
  UpdatePartnerThemePayload,
} from '@/shared/types/partners';
import { mockMetadata } from '@/mocks/generic/generic';
import { site } from '@/mocks/sites/mockSites';
import { Admin } from '@/shared/types/admins';

export const partnerStatus = [
  StatusStaticReference.ACTIVE,
  StatusStaticReference.DELETED,
];

const partnerAdminStatus = [...partnerStatus, StatusStaticReference.INVITED];

export const mockPartners: Partner[] = partnerStatus.map((status, index) => ({
  name: `Partner ${index + 1}`,
  email: `partner-${index + 1}@gmail.com`,
  adminsNumber: 1,
  customersNumber: 0,
  createdBy: {
    email: 'admin@gmail.com',
    name: 'Vitor Coelho',
  },
  updatedAt: 1725447712585,
  status,
  language: Locale.US,
  reference: `partner-${index + 1}`,
  site,
  theme: {
    logo: 'logo',
    primaryColorHex: 'primary-color',
    favicon: 'icon',
    name: 'default-theme',
    opacity: '0.06',
  },
}));

export const mockPartnerAdmins: Admin[] = partnerAdminStatus.map(
  (status, index) => ({
    reference: `partner-admin-${index + 1}`,
    name: `Admin ${index + 1}`,
    email: `partner-admin-${index + 1}@gmail.com`,
    status,
  }),
);

export const mockUpdatePartnerPayload: EditPartnerPayload = {
  name: 'partner-1',
  site: {
    state: 'state-1',
    name: 'site-1',
    reference: 'site-1',
    city: 'city-1',
    country: 'country-1',
    timezone: 'ianaTimezone-1',
  },
  language: 'en-US',
};

export const createPartnerPayloadMock: CreatePartnerPayload = {
  name: 'Test partner name',
  email: 'Test partner email',
  language: 'en-EN',
  siteName: 'Barcelona',
  siteCity: 'Barcelona',
  siteCountry: 'Spain',
  siteState: 'Catalonia',
  siteTimezone: 'Europe/Madrid',
  features: 'FEATURE_1;FEATURE_2',
  adminName: 'Test admin name',
  adminEmail: 'Test admin email',
  themeName: 'Test theme name',
  themeLogoFile: new File([], 'Logo.svg'),
  themeFavIconFile: new File([], 'Favicon.svg'),
  themePrimaryColorHex: '#dc143c',
  themePrimaryColorRgb: '220, 20, 60',
  themeSecondaryColorHex: '#dc143c',
  themeSecondaryColorRgb: '220, 20, 60',
  themeOpacity: '0.06',
};

export const updatePartnerTheme: UpdatePartnerThemePayload = {
  themeName: 'themeName',
  themeLogoFile: new File([], 'Logo.svg'),
  themeFavIconFile: new File([], 'Favicon.svg'),
  themePrimaryColorHex: 'themePrimaryColorHex',
  themePrimaryColorRgb: 'themePrimaryColorRgb',
  themeSecondaryColorHex: 'themeSecondaryColorHex',
  themeSecondaryColorRgb: 'themeSecondaryColorRgb',
  themeOpacity: 'themeOpacity',
};

export const getPartners = (): ApiResponse<{ partners: Partner[] }> => ({
  metadata: mockMetadata(),
  results: { partners: mockPartners },
});

export const getPartnerAdmins = (): ApiResponse<{
  admins: Admin[];
}> => ({
  metadata: mockMetadata(),
  results: { admins: [mockPartnerAdmins[0]] },
});
