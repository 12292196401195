import {
  ResponseMetadata,
  StatusStaticReference,
} from '@/shared/types/generic';
import {
  NewOrganization,
  NewOrganizationRequest,
  Organization,
  IOrganizationDetails,
  SalesChannels,
} from '@/shared/types/organizations';
import { getRandomReference } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { site } from '../sites/mockSites';
import { mockFeatures } from '../features/mockFeatures';

export function mockOrganizationDetails(): IOrganizationDetails {
  return {
    name: 'Organization name',
    email: 'test@test.com',
    language: 'es-ES',
    site: {
      country: 'Spain',
      name: 'Barcelona',
      city: 'Barcelona',
      reference: getRandomReference(),
      timezone: {
        utcOffset: 'UTC+02:00',
        ianaTimezone: 'Europe/Madrid',
      },
    },
    clientManager: {
      reference: getRandomReference(),
      name: 'Client Manager Name',
      email: 'clientManager@gmail.com',
    },
  };
}

export const createOrganization = (
  reference: string,
  name: string,
  languageCode: string,
  currencyCode: string,
  status: Organization['status'],
  exchangeRate: number,
): Organization => {
  return {
    modifiedBy: 'me',
    createdAt: 1676562030832,
    updatedAt: 1676562030832,
    reference,
    name,
    status,
    exchangeRate,
    languageCode,
    currencyCode,
    numberOfRoles: 5,
    site,
    clientManager: {
      reference: 'clientManagerReference',
      name: 'Client Manager Name',
      email: 'clientManager@gmail.com',
    },
    features: mockFeatures().results.features,
  };
};

export const organizations = [
  createOrganization(
    '1',
    'Org1',
    'pt-PT',
    'GBP',
    StatusStaticReference.ACTIVE,
    10,
  ),
  createOrganization(
    '2',
    'Org2',
    'en-US',
    'USD',
    StatusStaticReference.ACTIVE,
    10,
  ),
  createOrganization(
    '3',
    'Org3',
    'en-US',
    'EUR',
    StatusStaticReference.DELETED,
    10,
  ),
];

export const metadata: ResponseMetadata = {
  totalItems: organizations.length,
  lastUpdated: 1595030400000,
};

export const getOrganizations = () => ({
  metadata,
  results: {
    organizations,
  },
});

export const exampleSalesChannelOption: SalesChannels[] = [
  {
    name: 'salesChannelName1',
    reference: 'salesChannelReference1',
    email: 'salesChannelEmail1',
  },
  {
    name: 'salesChannelName2',
    reference: 'salesChannelReference2',
    email: 'salesChannelEmail2',
  },
  {
    name: 'salesChannelName3',
    reference: 'salesChannelReference3',
    email: 'salesChannelEmail3',
  },
];

export const exampleNewOrg: NewOrganization = {
  reference: 'reference',
  organizationName: 'Test Org',
  languageCode: 'pt-PT',
  currencyCode: 'EUR',
  exchangeRate: 2,
  salesChannel: exampleSalesChannelOption[0],
  siteName: 'test',
  siteCity: 'Lisbon',
  siteCountry: 'Portugal',
  siteState: 'Lisbon',
  siteTimezone: 'teste',
  customRoles: [],
  defaultRoles: [],
};

export const exampleNewOrgRequest: NewOrganizationRequest = {
  organizationName: 'Test Org',
  languageCode: 'pt-PT',
  currencyCode: 'EUR',
  exchangeRate: 2,
  salesChannelReference: 'salesChannelReference',
  featureCodesToEnable: [],
  siteName: 'test',
  siteCity: 'Lisbon',
  siteCountry: 'Portugal',
  siteState: 'Lisbon',
  siteTimezone: 'teste',
  editedCustomRoleLevels: [],
  newCustomRoleLevels: [],
  deletedCustomRoleLevels: [],
};
